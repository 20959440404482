import axios, { AxiosInstance } from 'axios';
import {
  T_OrderWithCoupon,
  T_BasketItem,
  T_Order,
  T_Product,
  T_Coupon,
  T_CouponRetrieval,
  T_OrderRequest,
  T_CURATED_BUNDLE,
  T_PromotionRetrieval,
  T_Channel,
  T_PromotionWithCoupon,
} from '../types';
import { MyAccountV2Service } from '..';
import { ChannelJSONConfigService } from './channelJsonConfig';
import {
  BACKEND_ENDPOINT,
  CHANNELS_JSON_CONFIG_URL,
  CHANNEL_JSON_CONFIG_URL,
  GRAPHQL_URL,
  productSkuIds,
} from '../config';
import { ApolloClient, InMemoryCache, NormalizedCacheObject, createHttpLink } from '@apollo/client';

export class KonakartService {
  private kkAgent: AxiosInstance;
  private channelJSONConfigService: any;
  private channelsJSONConfigService: any;
  private myAccountV2Service: MyAccountV2Service;
  private myAccountAgent: AxiosInstance = axios.create({ baseURL: BACKEND_ENDPOINT });
  private gqlAgent: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: createHttpLink({ uri: GRAPHQL_URL }),
    cache: new InMemoryCache(),
  });

  constructor(kkAgent: AxiosInstance) {
    this.kkAgent = kkAgent;
    this.channelJSONConfigService = new ChannelJSONConfigService(axios.create({ baseURL: CHANNEL_JSON_CONFIG_URL }));
    this.channelsJSONConfigService = new ChannelJSONConfigService(axios.create({ baseURL: CHANNELS_JSON_CONFIG_URL }));
    this.myAccountV2Service = new MyAccountV2Service(this.myAccountAgent, this.gqlAgent);
  }

  public getTempCustomerId = async () => (await this.kkAgent.post<{ r: string }>('/customerId')).data;

  // overrideProductInfoFromKK - overrides Konakart product details with info from Liferay
  // catalogueType - 001 for backbook, 002 for frontbook, use to fetch products from konakart
  public getAllProduct = async (overrideProductInfoFromKK: boolean, catalogueType?: string) => {
    // Get KK product information
    const { data } = await this.kkAgent.get<T_Product[]>(catalogueType ? `/products/${catalogueType}` : '/v3/products');

    if (overrideProductInfoFromKK) {
      const [channelIconList, channels, cardSection] = await Promise.all([
        this.channelJSONConfigService.getChannelConfig(),
        this.channelsJSONConfigService.getChannelConfig(),
        this.myAccountV2Service.getCardContainer({ usePreview: false }),
      ]);

      // Override data from KK with data from Liferay-connect-service
      for (let product of data) {
        // let prod = productLiferayData?.find((d: T_Product_LR) => d.sku === product.sku);
        let prod = cardSection.data?.cardContainer.find((d: any) => d?.sku === product.sku);
        product.videoUrl = prod?.videoUrl ?? product?.videoUrl;
        product.horizontalVideoUrl = prod?.horizontalVideoUrl ?? product.horizontalVideoUrl;
        product.horizontalImageUrl = prod?.horizontalImageUrl?.url ?? product.horizontalImageUrl;
        product.custom1 = prod?.mainImageUrl?.url ?? product.custom1;
        product.logoImageUrl = prod?.logoImageUrl?.url ?? product.logoImageUrl;
        product.description = prod?.description && prod?.description !== '' ? prod.description : product.description;
        product.explorePopupCopy = prod?.explorePopupCopy ?? product.explorePopupCopy;
        product.name = prod?.name && prod?.name !== '' ? prod.name : product.name;
        product.contractDescription = prod?.contractDescription ?? product.contractDescription;
        product.showExplorePopup = prod?.showExplorePopup && prod?.showExplorePopup ? true : false;
        product.linkLabel = prod?.linkLabel ?? 'Explore';
        product.linkUrl = prod?.linkUrl ?? product.linkUrl;
        product.iconImageUrl = prod?.iconImageUrl?.map((img: any) => img.iconImageUrl);
        product.popupCards = prod?.popupCards?.map((items: any) => ({
          ...items,
          popupCardIconURL: items.popupCardIconUrl.url,
          popupCardImageURL: items.popupCardImageUrl.url,
        }));

        product.channels = channels.filter((c: T_Channel) => c.sku === product.sku);

        // Add channel list icon
        switch (product.sku) {
          case productSkuIds.starter.primary:
            product.iconImageUrlFromJSON = channelIconList?.Starter;
            break;
          case productSkuIds.sport.primary:
            product.iconImageUrlFromJSON = channelIconList?.Sport;
            break;
          case productSkuIds.movies.primary:
            product.iconImageUrlFromJSON = channelIconList?.Movies;
            break;
          case productSkuIds.entertainment.primary:
            product.iconImageUrlFromJSON = channelIconList?.Entertainment;
            break;
          case productSkuIds.soho.primary:
            product.iconImageUrlFromJSON = channelIconList?.SoHo;
            break;
        }
      }
    }
    return data;
  };

  public getOrder = async (customerId: string) => {
    return (
      await this.kkAgent.get<T_Order>('/order', {
        params: {
          customerId,
        },
        headers: {
          'split-entries': 'true',
        },
      })
    ).data;
  };

  public getAllBundles = async (): Promise<T_CURATED_BUNDLE[]> => {
    // const bundlesLiferayData = await this.liferayConnectService.getBundles();

    return [];
  };

  // Check coupon whether valid or not
  public checkCouponCode = async (couponCode: string) =>
    (await this.kkAgent.post<boolean>('/checkCoupon/' + couponCode, {})).data;

  public applyCouponCode = async (couponCode: string, customerId: string) =>
    (
      await this.kkAgent.post<T_OrderWithCoupon>('/applyCoupon/' + couponCode, {
        customerId: customerId,
      })
    ).data;

  // Get the offer associated with the product you are trying to add.
  public getCouponCode = async (couponCode: string, customerId: string, productId: number) =>
    (
      await this.kkAgent.post<T_Coupon | undefined>('/getCoupon/' + couponCode, {
        customerId: customerId,
        productId: productId,
      })
    ).data;

  // Get back a coupon object with the coupon code passed
  public getCoupon = async (couponCode: string) => {
    const couponRetrieval = (await this.kkAgent.get<T_CouponRetrieval>('/getCouponOnly/' + couponCode)).data;
    // important to note that in KK a coupon code is not unique, but is implemented in a way that will ensure its unique.
    // so the first element if content exists will be the coupon object
    return couponRetrieval ? couponRetrieval.r[0] : undefined;
  };

  // Get back a promotion objects with the coupon code passed
  public getPromotions = async (couponCode: string) => {
    const couponRetrieval = (await this.kkAgent.get<T_PromotionRetrieval>('/getPromotionsPerCoupon/' + couponCode))
      .data;
    // important to note that in KK a coupon code is not unique, but is implemented in a way that will ensure its unique.
    // so the first element if content exists will be the coupon object
    return couponRetrieval && couponRetrieval.r ? couponRetrieval.r[0] : undefined;
  };

  // Get back promotion objects with coupone from coupon codes
  public getPromotionsWithCouponFromCouponCode = async (couponCodes: string): Promise<T_PromotionWithCoupon[]> => {
    const couponRetrieval = (
      await this.kkAgent.get<T_PromotionWithCoupon[]>('/getPromotionsWithCoupon/?coupons=' + couponCodes)
    ).data;

    return couponRetrieval;
  };

  public updateOrder = async (customerId: string, products: T_OrderRequest[], coupons?: string[]): Promise<T_Order> => {
    let { data: order } = await this.kkAgent.post<T_Order>(
      '/updateOrder',
      {
        customerId,
        products,
        coupons,
      },
      {
        headers: {
          'split-entries': 'true',
        },
      }
    );

    // sort products in order, large rating comes first
    return {
      ...order,
      orderProducts: order.orderProducts.sort((a, b) => {
        return b.product.rating - a.product.rating;
      }),
    };
  };

  /**
   * For manage packages - where person has existing promotion
   * @param customerId
   * @param products
   * @param coupons
   */
  public updateCampaignOrder = async (
    customerId: string,
    products: Pick<T_BasketItem, 'productId' | 'quantity' | 'finalPriceIncTax'>[],
    coupons?: string[]
  ): Promise<T_Order> => {
    let { data: order } = await this.kkAgent.post<T_Order>('/updateCampaignOrder', {
      customerId,
      products,
      coupons,
    });

    // sort products in order, large rating comes first
    return {
      ...order,
      orderProducts: order.orderProducts.sort((a, b) => {
        return b.product.rating - a.product.rating;
      }),
    };
  };
}
