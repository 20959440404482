import React from 'react';
import { useState, useEffect } from 'react';
import { Button } from './button';
import { GetOrderConfirmationQuery } from '@sky-tv-group/graphql';
import { getStyledText, useSplitIO, SPLITIO_KEY, OrderService, PACKAGE_TYPE } from '@sky-tv-group/shared';
import { ConfirmationStep } from './confirmationStep';
import { ReactComponent as VerifyAccountIcon } from '../../assets/icons/account-icon.svg';
import { ReactComponent as TVIcon } from '../../assets/icons/qrcode-icon.svg';
import { ReactComponent as FibreIcon } from '../../assets/icons/fibre-icon.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/right-arrow.svg';
import './style.css';
interface Props {
  email?: string;
  firstName?: string;
  accountNumber?: string;
  data?: GetOrderConfirmationQuery;
  orderService?: OrderService;
  packageType: string;
  setEmailError: (message: string) => void;
}

const ConfirmationScreen = ({ firstName, accountNumber, email, data, orderService, packageType, setEmailError }: Props) => {
  const timer = 60;
  const [countdown, setCountdown] = useState(timer);
  const [verifyAccountFlag] = useSplitIO(SPLITIO_KEY.SKYWEB_ORD_CNFRM_MAIL_BLOCK);
  const replaceItems = (node: string | null, item1: string[], item2: any) => {
    if (node) {
      node = node?.replace(`{{${item1[0]}}}`, item2[0]);
      node = node?.replace(`{{${item1[1]}}}`, item2[1]);
      return node;
    }
  };

  const helpLinkRegister = 'https://help.sky.co.nz/s/article/I-m-having-trouble-registering-for-My-Account';
  const helpLinkRouter = 'https://help.sky.co.nz/s/article/Can-I-bring-my-own-router';

  useEffect(() => {
    let timer: undefined | NodeJS.Timer|any;
    if (accountNumber && countdown > 0) {
      timer = setInterval(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearInterval(timer!);
  }, [countdown]);

  if (data?.orderConfirmation) {
    let resultdata: Record<string, any> = {};
    data.orderConfirmation?.forEach(object => {
      switch (object?.category) {
        case 'header':
          resultdata[object?.category] = {
            header: replaceItems(object?.header, ['name'], [firstName!]),
            description: getStyledText(
              replaceItems(object?.description, ['email', 'AccountNumber'], [email!, accountNumber]),
              'text-blue-light font-bold'
            ),
            skylogo: object?.iconSrc,
            skylogoMobile: object?.iconMobileSrc,
          };
          break;
        case 'VerifyAccount':
          resultdata[object?.category] = {
            image: object?.iconSrc,
            imageDescription: object?.category,
            header: object?.header,
            description: getStyledText(replaceItems(object?.description, ['email', 'helpLink'], [email!, `<a href="${helpLinkRegister}" class="text-blue-light underline"> this</a>`]), ''),
            btnText: object?.buttonCtaName,
          };
          break;
        case 'skyGo':
          resultdata[object?.category] = {
            skyGo: object?.iconSrc,
            skyGoMobile: object?.iconMobileSrc,
            header: object?.header,
            description: object?.description,
            buttonCtaName: object?.buttonCtaName,
            buttonLink: object?.buttonLink,
            appLogoLargeSrc: object?.appLogoLargeSrc,
            appLogoSmallSrc: object?.appLogoSmallSrc,
          };
          break;
        case 'skyMyAccountApp':
          resultdata[object?.category] = {
            skyapp: object?.iconSrc,
            skyappMobile: object?.iconMobileSrc,
            header: object?.header,
            description: object?.description,
            buttonCtaName: object?.buttonCtaName,
            buttonLink: object?.buttonLink,
            appLogoLargeSrc: object?.appLogoLargeSrc,
            appLogoSmallSrc: object?.appLogoSmallSrc,
          };
          break;
        case 'TrackAndActivateTV':
          resultdata[object?.category] = {
            image: object?.iconSrc,
            imageDescription: object?.category,
            header: object?.header,
            description: getStyledText(replaceItems(object?.description, ['selfInstall', 'techInstall'], ['<b>Self Install:</b>', '<b>Technician Install:</b>']), ''),
            btnText: object?.buttonCtaName,
          };
          break;
        case 'YourFibreConnection':
          resultdata[object?.category] = {
            image: object?.iconSrc,
            imageDescription: object?.category,
            header: object?.header,
            description: getStyledText(replaceItems(object?.description, ['videoLink'], [`<a href="${helpLinkRouter}" class="text-blue-light underline">help article here</a>`]), ''),
            btnText: object?.buttonCtaName,
          };
          break;
        default:
          break;
      }
    });
    const handleClick = (name: string) => {
      if (name === resultdata?.skyGo?.header) {
        window.location.href = resultdata?.skyGo?.buttonLink;
      } else window.location.href = resultdata?.skyMyAccountApp?.buttonLink;
    };

    const handleResendVerificationClick = async () => {
      setCountdown(timer);
      setEmailError('');
      try {
        const emailVerificationResponse: any = await orderService?.postReSendVerificationMail(accountNumber!, email!);
        if (emailVerificationResponse.code !== 'OK') {
          setEmailError(emailVerificationResponse.message);
        }
      } catch (error) {
        console.log(error);
        setEmailError('Unable to send verification mail');
      }
    };

    const step2header = (packageType === PACKAGE_TYPE.DTH_BROADBAND) ? 'Activate and Connect' :
      (packageType === PACKAGE_TYPE.DTH) ? 'Activate Device' :
        (packageType === PACKAGE_TYPE.BROADBAND) ? 'Fibre Connection' : '';

    return (
      <>
        <div className="mt-6 md:mt-10 text-center sky-text-midnight">
          <div className="mt-4 header-style mb-4">{resultdata?.header?.header}</div>
          {accountNumber && (
            <div style={{ fontWeight: 450, whiteSpace: 'pre-line' }} className="text-base leading-5">
              <div dangerouslySetInnerHTML={{ __html: resultdata?.header?.description }} />
            </div>
          )}

          <br /><br />
          <div className="flex flex-col font-skyBold text-center text-slate-900">
            <div className="text-center confirmation-step-title">Your next steps:</div>
            <div className="flex justify-center items-center mt-4 w-full">
              <div className="flex flex-col md:flex-row items-center self-stretch gap-5 my-2">
                <div className="flex flex-col justify-center self-stretch ">
                  <div className="self-center">
                    <VerifyAccountIcon />
                  </div>
                  <div className="confirmation-step-title mt-3">Verify Account</div>
                </div>
                <div className="my-4 stepsumm-arrow">
                  <RightArrow />
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-row justify-center self-stretch">
                    {(packageType === PACKAGE_TYPE.DTH || packageType === PACKAGE_TYPE.DTH_BROADBAND) &&
                      <TVIcon />}
                    {(packageType === PACKAGE_TYPE.BROADBAND || packageType === PACKAGE_TYPE.DTH_BROADBAND) &&
                      <FibreIcon />}
                  </div>
                  <div className="confirmation-step-title mt-3">{step2header}</div>
                </div>
                <div className="my-4 stepsumm-arrow">
                  <RightArrow />
                </div>
                <div className="md:font-skyBlack text-5xl">
                  <b>Enjoy</b>
                </div>
              </div>
            </div>
          </div>
          <div className="border-line">
          </div>

          <ConfirmationStep
            imageUrl={resultdata.VerifyAccount?.image?.url}
            header={resultdata?.VerifyAccount?.header}
            btnText={resultdata.VerifyAccount?.btnText}
            description={resultdata?.VerifyAccount?.description}
            countdown={countdown}
            btnClick={handleResendVerificationClick}
          />
          {(packageType === PACKAGE_TYPE.DTH || packageType === PACKAGE_TYPE.DTH_BROADBAND) &&
            (<ConfirmationStep
              imageUrl={resultdata.TrackAndActivateTV?.image?.url}
              header={resultdata.TrackAndActivateTV?.header}
              description={resultdata?.TrackAndActivateTV?.description}
              countdown={0}
            />)}
          {(packageType === PACKAGE_TYPE.DTH_BROADBAND || packageType === PACKAGE_TYPE.BROADBAND) &&
            (<ConfirmationStep
              imageUrl={resultdata.YourFibreConnection?.image?.url}
              header={resultdata.YourFibreConnection?.header}
              description={resultdata?.YourFibreConnection?.description}
              countdown={0}
            />)}
        </div>

        <div className="flex   md:mt-10 mb-14  sky-app ">
          <div className="mr-5 flex flex-1 flex-col md:border-grey-c5 items-center ">
            <img
              style={{ width: 'auto', objectFit: 'contain' }}
              className="flex-1 max-580"
              srcSet={`${resultdata?.skyGo?.skyGo?.url} 1024w,${resultdata?.skyGo?.skyGoMobile?.url} 480w`}
              sizes="(min-width:768px) 1024px,480px"
              alt={resultdata?.skyGo?.skyGo?.title}
            />
            <div className="mt-4 text-center header-style-adv font-skyBold mb-4">{resultdata?.skyGo?.header}</div>
            <div className="text-center text-base leading-5">{resultdata?.skyGo?.description}</div>
            <div className="text-center mt-6 mb-6 ">
              <Button
                variant="primary"
                className="sky-button--primary-pure w-280px"
                onClick={() => handleClick(resultdata?.skyGo?.header)}>
                {resultdata?.skyGo?.buttonCtaName}
              </Button>
            </div>
            <img
              src={resultdata?.skyGo?.appLogoSmallSrc?.url}
              alt={resultdata?.skyGo?.appLogoSmallSrc?.title}
              className="responsive-image mobile"
            />
            <img
              src={resultdata?.skyGo?.appLogoLargeSrc?.url}
              alt={resultdata?.skyGo?.appLogoLargeSrc?.title}
              className="responsive-image desktop"
            />
          </div>
          <div className="line "></div>
          <div className="ml-5 flex flex-1 flex-col items-center">
            <img
              alt={resultdata?.skyMyAccountApp?.skyapp?.title}
              srcSet={`${resultdata?.skyMyAccountApp?.skyapp?.url} 1024w,${resultdata?.skyMyAccountApp?.skyappMobile?.url} 480w`}
              sizes="(min-width:768px) 1024px,480px"
              className="flex-1 max-580"
              style={{ width: 'auto', objectFit: 'contain' }}
            />

            <div className="mt-4 text-center header-style-adv font-skyBold mb-4">{resultdata?.skyMyAccountApp?.header}</div>
            <div style={{ minHeight: '70px' }} className="text-center text-base leading-5 ">
              {resultdata?.skyMyAccountApp?.description}
            </div>
            <div className="text-center mt-6 mb-6 ">
              <Button
                variant="primary"
                className="sky-button--primary-pure w-280px"
                onClick={() => handleClick(resultdata?.skyMyAccountApp?.header)}>
                {resultdata?.skyMyAccountApp?.buttonCtaName}
              </Button>
            </div>
            <img
              src={resultdata?.skyMyAccountApp?.appLogoSmallSrc?.url}
              alt={resultdata?.skyMyAccountApp?.appLogoSmallSrc?.title}
              className="responsive-image mobile"
            />
            <img
              src={resultdata?.skyMyAccountApp?.appLogoLargeSrc?.url}
              alt={resultdata?.skyMyAccountApp?.appLogoLargeSrc?.title}
              className="responsive-image desktop"
            />
          </div>
        </div>
      </>
    );
  } else return null;
};
export { ConfirmationScreen };
