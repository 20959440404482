import React, { useEffect } from 'react';
import { NotFound } from './notFound';
import { Helmet } from 'react-helmet';

interface Props {
  redirectURL: string;
  label: string;
}

const NotFoundContainer = ({ redirectURL = '/', label = 'Go Home' }: Props) => {
  return (
    <>
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <div className="container mx-auto flex flex-col md:flex-row h-200 md:h-144 py-8">
        <div className="flex flex-col md:flex-1 relative items-center">
          <NotFound />
          <h1 className="sky-text-daylight md:sky-h1 sky-h1 absolute top-48 md:top-1/2" style={{ fontSize: '96px' }}>
            404
          </h1>
        </div>
        <div className="flex flex-col flex-1 items-center text-center">
          <div className="sky-text-daylight md:sky-h1 sky-h1 pt-20 pb-14 md:py-14">Lost at Sky?</div>
          <div className="sky-h5 md:sky-h5 sky-text-midnight pb-14 px-4 md:px-10">
            Looks like something you are looking for can not be found. We suggest you
          </div>
          <a className="sky-button sky-button--primary-light cursor-pointer md:inline" href={redirectURL}>
            {label}
          </a>
        </div>
      </div>
    </>
  );
};

export { NotFoundContainer };
